import React from "react";
import HeaderWidget from "../widgets/HeaderWidget";
import backgroundImage from "../assets/images/background-image.jpg";
import FooterWidget from "../widgets/FooterWidget";
import PartnerWidget from "../widgets/PartnerWidget";

import costaCoffeeLogo from "../assets/icons/costa-coffee-logo.png";

function InfoScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          // src="/images/MEBF_Website_BG_2025.jpg"
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto mt-5  w-full  mb-5 min-h-[70vh] flex flex-col items-center justify-center ">
            <div
              className="relative border-8 border-white  text-white bg-fixed flex flex-col items-center justify-center px-5 py-10 mt-5 md:w-2/3 w-full mb-5  "
              // style={{
              //   backgroundImage: "url('/images/bg-red-costa.png')",
              // }}
            >
              <img
                src="/images/bg-red-costa.png"
                className="w-full  h-full absolute object-fill -z-10"
              />
              {/* <div
                className=" absolute w-full h-full inset-0 bg-contain bg-center  bg-repeat"
                style={{
                  backgroundImage: "url('/images/bg-red-costa.png')",
                  zIndex: -1,
                }}
              ></div> */}
              {/* <div className="  my-2  mx-auto w-full min-h-screen ">
                <iframe
                  src={
                    "/files/Costa_Goldex_Book_Award_Shortlist_Press_Release.pdf#toolbar=0"
                  }
                  title="PDF Viewer"
                  width="100%"
                  style={{ border: "none", width: "100%", height: "100vh" }}
                ></iframe>
              </div> */}
              {/* <p className="text-center  font-medium text-2xl p-3   w-max px-3 mx-auto mt-4 md:mb-4 mb-2">
                <a
                  href="/costa-glodex-book-award-info.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-[#a15656] px-5 py-3 rounded shadow md:text-xl text-sm font-bold text-white"
                >
                  Download info about the award
                </a>
              </p> */}
              {/* <p className="text-center font-medium  p-3 text-2xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                <a
                  href="/costa-glodex-book-award-entry-form.pdf"
                  target={"_blank"}
                  rel="noreferrer"
                  className="bg-[#a15656] px-5 py-3 rounded shadow  md:text-xl text-sm font-bold  text-white "
                >
                  Download entry form
                </a>
              </p> */}
              <div className="   mx-auto">
                {/*  */}
                <div className="flex flex-row justify-end">
                  <img
                    src={"/images/icon-top-costa.png"}
                    className="lg:size-28  size-28 "
                    alt="Costa Coffee"
                  />
                </div>

                <div className="lg:text-5xl text-2xl font-bold lg:mt-0 mt-4">
                  Costa Goldex Morocco
                </div>
                <div className="lg:text-5xl text-2xl font-extrabold">
                  Book Award 2024
                </div>
                <div className="flex lg:flex-row flex-col-reverse items-center my-3">
                  <div className="lg:w-1/2 w-full text-justify lg:text-3xl text-sm">
                    After careful deliberation, the judging panel for the 2024
                    Costa Goldex Morocco Book Award have decided on their
                    shortlist of three titles to be consider for the inaugural
                    prize.
                  </div>
                  <div className="lg:w-1/2 w-full my-3 mx-auto lg:p-3 p-5 ">
                    <div className="relative w-2/3 mx-auto ">
                      <div className="z-10   absolute border-white border h-full w-full -bottom-4 -left-4"></div>
                      <img
                        className="z-40  relative "
                        src="/images/Screenshot 2024-11-20 at 10.54.58.png"
                        alt="Costa Goldex Morocco Book Award 2024"
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4  text-justify lg:text-3xl text-sm">
                  Entries for the award included fiction, non-fiction, poetry
                  and short story collections, demonstrating the growing
                  community of Moroccans writing in English, as well as the
                  growing appetite for English language books by Moroccan
                  writers. The judges were impressed by the diverse voices
                  present amongst the entries, dealing with themes which reflect
                  various realities, hopes and feelings in Moroccan society.
                </div>
                <div className="h-4"></div>
                <div className="lg:text-center text-justify my-6 lg:text-3xl ">
                  The panel, composed of Moroccan and non-Moroccan professionals
                  in the publishing and literary world, have chosen these three
                  books for{" "}
                  <span className="font-bold">THE FINAL SHORTLIST</span>
                </div>
                <div className=" flex md:flex-row flex-col my-5">
                  <div className="md:w-1/3 w-full px-1">
                    <div className="p-2">
                      <img
                        className="md:w-full w-1/2 mx-auto"
                        src="/images/UTvQ2J4.png"
                      />
                    </div>
                    <div className="text-center my-2 font-bold lg:text-base text-sm">
                      The House on Butterfly Street - Mhani Alaoui.
                    </div>
                    <div className="text-center  lg:text-base text-sm ">
                      Editions la Croisée des Chemins, 2023
                    </div>
                  </div>
                  <div className="md:w-1/3 w-full  px-1">
                    <div className="p-2">
                      <img
                        className="md:w-full w-1/2 mx-auto"
                        src="/images/gZbqxgA.png"
                      />
                    </div>
                    <div className="text-center my-2 font-bold  lg:text-base text-sm">
                      The Testament and other Modern Stories – Mbarek Ahellal.
                    </div>
                    <div className="text-center  lg:text-base text-sm ">
                      Editions Emanya, 2023
                    </div>
                  </div>
                  <div className="md:w-1/3 w-full  px-1">
                    <div className="p-2">
                      <img
                        className="md:w-full w-1/2 mx-auto"
                        src="/images/J2dLZQY.png"
                      />
                    </div>
                    <div className="text-center my-2 font-bold  lg:text-base text-sm">
                      Beneath the Table – Ghita Ayasse Ouamane.
                    </div>
                    <div className="text-center  lg:text-base text-sm">
                      Self-published, 2024
                    </div>
                  </div>
                </div>
                <div className="lg:text-center text-justify my-5 lg:text-3xl text-sm">
                  The winner will be announced in December at a press conference
                  to be held at the Jaal Resort, Marrakech. The winner will
                  receive a cash prize of 25,000 dirhams, accompanied by a
                  national promotional campaign orchestrated by Costa Goldex
                  Morocco to promote the winning author and enhance the sales of
                  their book.
                </div>
                <div className="h-4"></div>
                {/*  */}
                <div className="lg:w-3/4 w-full mt-5">
                  <div className="font-extrabold lg:text-4xl ">
                    The House on Butterfly Street - Mhani Alaoui.
                  </div>
                  <div className="font-bold lg:text-3xl text-sm">
                    Editions la Croisée des Chemins, 2023
                  </div>
                </div>

                <div className="flex lg:flex-row flex-col  my-4">
                  <div className="lg:w-1/3 w-2/3 mx-auto lg:p-3 p-5">
                    <div className="relative ">
                      <div className="z-10   absolute border-white border h-full w-full -bottom-4 -left-4"></div>
                      <div className="">
                        <img
                          className="z-40 relative "
                          src="/images/authers/mhani-alaoui-the-house-on-butterfly-street.jpg"
                          alt="The House on Butterfly Street - Mhani Alaoui."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-2/3 w-full  text-sm font-extralight px-3 text-justify">
                    A great read, with more than enough to keep the reader
                    guessing and a well constructed story with clear societal
                    messages about Morocco. An accomplished novel, it is
                    sturdily crafted, with rising action, small hooks at the end
                    of most chapters to drive the reader on, distinct characters
                    and a satisfying ending. Readers will love picturing the
                    scenes of Casablanca as well, testament to the well-
                    constructed narrative and structure. It explores the
                    different sorts of precarities that affect the lives of
                    women across social strata, and how the hauntings of the
                    past might affect the future, but don’t determine it.
                  </div>
                </div>
                {/*  */}
                <div className="lg:w-3/4 w-full mt-5">
                  <div className="font-extrabold  lg:text-4xl ">
                    Beneath the Table – Ghita Ayasse Ouamane.
                  </div>
                  <div className="font-bold lg:text-3xl text-sm">
                    Self-published, 2024.
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col-reverse items-center my-4">
                  <div className="lg:w-2/3 w-full text-sm font-extralight px-3 text-justify">
                    In this depiction of the immediate aftermath of the
                    earthquake in Morocco on September 8th 2023, the voice of
                    the writer is unique and strong, and besides the obvious
                    emotional resonance of the subject matter there is real
                    heart and an attempt by the writer to let us into her world.
                    There’s something special about the narrative voice,
                    something that vibrates and really looks at its characters
                    and situations and tries to bring the reader into
                    complicated moments. There’s something truly real and
                    honestly felt about them and the narrator’s understanding of
                    the world and the people around her.
                  </div>

                  <div className="lg:w-1/3 w-2/3 mx-auto lg:p-3 p-5">
                    <div className="relative ">
                      <div className="z-10   absolute border-white border h-full w-full -bottom-4 -left-4"></div>
                      <img
                        className="z-40 relative "
                        src="/images/667ffbe8746a0e04c5a95baa_beneath-the-table-cover-min.png"
                        alt="Beneath the Table – Ghita Ayasse Ouamane."
                      />
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="lg:w-3/4 w-full mt-5">
                  <div className="font-extrabold lg:text-4xl">
                    The Testament and other Modern Stories – Mbarek Ahellal.
                  </div>
                  <div className="font-bold lg:text-3xl text-sm">
                    Editions Emanya, 2023
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col my-4">
                  <div className="lg:w-1/3 w-2/3 mx-auto lg:p-3 p-5">
                    <div className="relative ">
                      <div className="z-10   absolute border-white border h-full w-full -bottom-4 -left-4"></div>
                      <img
                        className="z-40 relative "
                        src="/images/image.jpeg"
                        alt="The House on Butterfly Street - Mhani Alaoui."
                      />
                    </div>
                  </div>
                  <div className="lg:w-2/3 w-full text-sm font-extralight px-3 text-justify">
                    In this compilation, the author weaves together 25 short
                    stories that paint a vivid picture of Moroccan society. From
                    destitution and mixed marriages to the nostalgia of old
                    cinemas and the trials of aesthetic surgery. Each story
                    invites readers to a facet of life in Morocco. The judges
                    loved the simplicity and punchiness of the writing, which
                    portray seemingly insignificant snippets of life in Morocco.
                    There is a real sense of the writer as an observer of
                    everyday life and the coincidences and connections which
                    make it fascinating. Simple, emotional short stories that
                    come together to create a portrait of largely middle-class
                    or middle- class-aspirational life in Morocco.
                  </div>
                </div>
                <div className="h-4"></div>
                <div className="my-5 text-center text-xl">
                  All the authors of the shortlisted books will receive
                  commendations during the award ceremony, which will be held
                  during the Marrakech English Book Festival 2025 edition.
                </div>

                <div className="h-4"></div>
                <div className="my-2">
                  The inaugural Costa Goldex Morocco Book Award saw entries from
                  Moroccan authors writing in English, encompassing poetry,
                  non-fiction, self-help books, short story collections and
                  novels. All the shortlisted books for the award have their
                  merits, and the judges, who come from a range of professional
                  backgrounds in the world of books and publishing, would like
                  to thank all the writers who put their books forward for the
                  award. The judges have decided that the winner of the
                  inaugural Costa Goldex Morocco Book Award is:
                </div>
                <br />

                <div className="my-2 font-bold">
                  The House on Butterfly Street, by Mhani Alaoui.
                </div>
                <br />

                <div className="my-2 ">
                  Mhani Alaoui’s The House on Butterfly Street is a novel that
                  explores the different sorts of precarities that affect the
                  lives of women across social strata, and how the hauntings of
                  the past might affect the future, but don’t determine it.
                  <br />
                  <br />
                  The author provides a sharp analysis of Moroccan society and
                  raises the thorny issue of single mothers and children born
                  out of wedlock. Mhani Alaoui vividly portrays social injustice
                  through each of the characters’ life stories. She also
                  addresses the struggles of African immigrant workers in
                  Morocco. All the themes of the novel are relevant to
                  contemporary Morocco.
                  <br />
                  <br />
                  The judges unanimously decided that “The House on Butterfly
                  Street” is a worthy winner of the first Costa Goldex Morocco
                  Book Award. They felt that its themes, characters, dialogue
                  and plot combine to offer a contemporary polemic of social
                  issues in Morocco, as well as the traditions, people and
                  places that make Morocco special. It is hoped that the novel
                  will enter the canon of Moroccan literature written in English
                  and inspire other writers.
                </div>

                <div className="h-4"></div>
                <div className="text-center font-semibold text-2xl">
                  The festival runs from 17-19 January 2025.
                </div>
                <div className="flex flex-row justify-between items-center">
                  <img
                    src={"/images/bg-logo-mebf.png"}
                    className="lg:size-52  lg:w-max w-1/3 "
                    alt="Costa Coffee"
                  />
                  <img
                    src={"/images/bg-costa-coffe-icon.png"}
                    className="lg:size-52 lg:w-max w-1/3 "
                    alt="Costa Coffee"
                  />
                </div>
              </div>

              {/* <div className="  my-2  mx-auto">
                <p className=" text-center text-xl my-10 mx-auto w-full font-bold">
                  Costa Goldex Book Award Update September 2024
                </p>
                <p className="my-3  mx-auto w-full ">
                  Costa Goldex and the Marrakech English Book Association (MEBA)
                  are delighted to announce that entries are now closed for the
                  inaugural Costa Goldex Book Award, the brand-new annual book
                  award open to authors in Morocco who write and are published
                  in English.
                </p>
                <p className="my-3  mx-auto w-full ">
                  We have received an encouraging number of entries by Moroccan
                  authors covering a range of genres.
                </p>
                <p className="my-3  mx-auto w-full ">
                  We are also very pleased that entries have come not only from
                  established authors, with international publishers, but also
                  from first time authors sending in their self-published books.
                </p>
                <p className="my-3  mx-auto w-full ">
                  All the entries have now been passed onto our international
                  panel of judges, who are all professionals in the book
                  industry representing editors, publishers and book retailers.
                </p>
                <p className="my-3  mx-auto w-full ">
                  The judges will release a short list of the 3 finalists at the
                  beginning of November and we will announce the winner of the
                  2024 Award at the beginning of December.
                </p>
                <p className="my-3  mx-auto w-full ">
                  The winning author will be an official guest at the next
                  edition of the Marrakech English Book Festival (MEBF), on the
                  weekend of 17th to 19th January 2025, and will be presented
                  with his prize by Diljit Brar, CEO of the Goldex Group and
                  Yassin Adnan, President of the MEBF, at a special award
                  ceremony in Marrakech during the festival. The festival will
                  also mark the official opening of entries for the 2025 Costa
                  Goldex Book Award.
                </p>
                <p className="my-3  mx-auto w-full ">
                  We hope that this first year of the award, will encourage more
                  Moroccans to write and publish in English and we look forward
                  to receiving a new selection of entries for the 2025 award.
                </p>
                <p className="my-3  mx-auto w-full ">
                  Costa Goldex and the Marrakech English Book Association are
                  committed to supporting and encouraging the spread of English
                  and education throughout Morocco. We do this not only through
                  our partnership in the organization of the Marrakech English
                  Book Festival, but also through our active support of British
                  participation in Morocco’s annual international book
                  festivals, SILEJ in Casablanca and SIEL in Rabat.
                </p>
              </div> */}
              {/* <p className="text-center font-medium md:text-2xl text-xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                Send 3 hard copies of your book to:
                <br />
                <div className="text-base  ">
                  Marrakech English Book Association C/O Still Images <br />
                  Résidence Achraf
                  <br /> Appt 10 10 Rue Ibn Atya
                  <br /> Guéliz Marrakech 40020 Morocco
                </div>
              </p> */}
              {/* <p className="text-center font-medium md:text-2xl text-xl  w-full px-3  mx-auto mt-4 md:mb-4 mb-2">
                Email your completed entry form + pdf copy of your book to:
                <br />
                <a
                  className="text-[#a15656]"
                  target={"_blank"}
                  rel="noreferrer"
                  href="mailto:admin@mebookfest.com"
                >
                  admin@mebookfest.com
                </a>
              </p> */}
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default InfoScreen;
