export const AUTHORSLIST = [
  {
    name: "Hannah Ait Ahmed",
    slug: "hannah-ait-ahmed",
    image: "/images/authers/hannah-ait-ahmed.jpeg",
    images: [
      "/images/authers/hannah-ait-ahmed-Tom's-Tall-Stork-Story-Cover.jpg",
    ],
    bio: [
      `When not travelling through the Middle East, Hannah Ait-Ahmed can be found roaming the Devon countryside with her dog Joseph, where she is currently based.`,
      `Combining her love of Morocco and England, Tom's Tall Stork Story, is her first book coming in the wake of a lifetime of writing, illustrating and facilitating artistic and literary initiatives.`,
      `Hannah studied illustration a Plymouth Arts College, journalism at Goldsmiths College in London
and recently undertook a short course in Islamic illumination at Kings Foundation School of
Traditional arts.`,
      `'Creativity is for all', she insists.`,
      `'Creativity is happiness'.`,
    ],
  },
  {
    slug: "mhani-alaoui",
    name: "Mhani Alaoui",
    image: "/images/authers/mhani-alaoui.jpg",
    images: [
      // "/images/mhani-alaoui.jpeg",
      "/images/authers/mhani-alaoui-the-house-on-butterfly-street.jpg",
    ],
    bio: [
      "Mhani Alaoui is a Casablanca-based writer and anthropologist.",
      "She lived in the US for twelve years, where she began by studying comparative literature and the recent history and cultures of the Arab World. She then went on to receive a PhD in Anthropology from Princeton University, focusing on illegal migration and gray borderlands in the Western Mediterranean. She was a Professor of Sociology and Anthropology at the Casablanca School of Architecture until Summer 2021. ",
      "Mhani Alaoui holds positions with LADSIS (Laboratoire de Recherche sur les Différentiations Socio-Anthropologiques et les Identités Sociales) at the University of Ain Chock and with the Center for African Mediterranean Studies.",
      "Her first novel, Dreams of Maryam Tair, released in 2015, received the Independent Publisher Book Award and the Indiefab Award.",
      "Her second novel, Aya Dane, was released in Fall 2018.  A preview, The Expat, appeared in the Massachusetts Review, June 2018.",
      "Her third novel, The House on Butterfly Street, was released in the U.S. on Oct 24th, 2023. The Moroccan edition was released in December 2023. The House on Butterfly Street is a finalist in the Next Generation Indie Book Awards (Regional Fiction category) and long-listed for the Chanticleer literary awards.",
    ],
  },

  {
    slug: "mehrdokht-amini",
    name: "Mehrdokht Amini",
    image: "/images/authers/mehrdokht-amini.jpg",
    images: [
      "/images/authers/mehrdokht-amini-1.jpg",
      "/images/authers/mehrdokht-amini-3.jpg",
      "/images/authers/mehrdokht-amini-6.jpg",
      "/images/authers/mehrdokht-amini-8.jpg",
      "/images/authers/mehrdokht-amini-11.jpg",
      "/images/authers/mehrdokht-amini-14.jpg",
      "/images/authers/mehrdokht-amini-15.jpg",
    ],
    bio: [
      "Mehrdokht Amini is an Iranian-British children’s book illustrator living in England.",
      `She graduated with a degree in Graphic Design from Tehran University and has
worked as an illustrator for both educational and mainstream publishers ever since.
Mehrdokht started her career in Iran while still studying at university, working on
various projects for children’s magazines and books.`,
      `After moving to England in 2004, she continued working with publishers all around
the world. Mehrdokht Amini has illustrated many books for children
including Chicken in the Kitchen by Nnedi Okorafor, which won the 2016 Children’s
Africana Best Book Award, and Golden Domes and Silver Lanterns by Hena Khan.`,
      `Amini has been nominated for a Kate Greenaway Medal and been invited to
participate in the prestigious Bratislava Bienniale of Illustration.`,
      `Mehrdokht loves working with publishers who are interested in cultural diversity
because it gives her an excellent opportunity to undertake a close study of different
cultures and communities, gain a better understanding of those cultures and
appreciate people of different backgrounds to her own.`,
    ],
  },
  {
    slug: "abigail-assor",
    name: "Abigail Assor",
    image: "/images/authers/abigail-assor.jpg",
    images: [
      // "/images/abigail-assor.jpeg",
      // "/images/abigail-assor-photo-f-mantovani-editions-gallimard.jpg",
      // "/images/abigail-assor-photo-f-mantovani-editions-gallimard-2.jpeg",
      "/images/authers/abigail-assor-as-rich-as-the-king.jpg",
    ],
    bio: [
      "Abigail Assor is a young Moroccan author originally from Casablanca.  She studied sociology and philosophy in London, before moving to Paris to work in communications for the cultural and contemporary art.",
      "Abigail published her first novel, As Rich as the King, to great critical acclaim in 2021.  The book has subsequently been translated into 7 languages including English.",
    ],
  },
  {
    slug: "khalid-bekkaoui",
    name: "Khalid Bekkaoui",
    image: "/images/authers/khalid-bekkaoui.jpg",
    images: [
      "/images/authers/khalid-bekkaoui-voice-cover.jpg",
      "/images/authers/khalid-bekkaoui-Necklace-cover.jpg",
      "/images/authers/khalid-bekkaoui-translation-cover.jpg",
    ],
    bio: [
      "Khalid Bekkaoui was born in Fez in 1961. He holds an MA in 18th-century English fiction and a PhD in British theatrical Orientalism. Bekkaoui is a professor of English Comparative Literature and Cultural Studies at Sidi Mohamed Ben Abdellah University in Fez and the coordinator of the Moroccan Cultural Studies Master’s Programme.",
      "His book publications include: Moroccan Literature in English Translation (2022), Fassi Merchants Come to Manchester (2016), White Women Captives in North Africa (2011), The British Bride of Tangier (2011), Imagining Morocco: An Anthology of Anglo-American Short Fiction (2008), and Signs of Spectacular Resistance: The Spanish Moor and British Orientalism (1998).",
      "Bekkaoui is also an author of two novels, The Voice (2022) and  Necklace and Pomegranates (2024).",
    ],
  },
  {
    slug: "puneet-bhandal",
    name: "Puneet Bhandal",
    image: "/images/authers/puneet-bhandal-puneet-bhandal-veetical.JPG",
    images: [
      "/images/authers/puneet-bhandal-melody-queen cover.jpg",
      "/images/authers/puneet-bhandal-starlet-rivals-cover.jpg",
    ],
    bio: [
      `Puneet Bhandal is a former journalist with over 20 years of experience working
across newspapers and magazines. She began her career as a Bollywood film
journalist for a London-based newspaper. As Entertainments Editor for Eastern Eye,
she spent much of her time on Indian film sets, interviewing actors, directors, and
producers.`,
      `The inspiration for her Bollywood Academy fiction series came from her personal
experiences. Growing up in West London as a passionate Bollywood fan, Puneet
struggled to find books that connected her to her cultural roots and the magical world
of cinema. She drew from her real-life encounters with Bollywood personalities and
her experiences as a teenager navigating everyday challenges to craft the series.`,
      `The first two books, Starlet Rivals and Melody Queen, offer readers a glimpse into
the glamorous world of Bollywood. Set in Mumbai on the fictional Kohinoor Island at
a performing arts academy, the series brings the allure of Bollywood to life. Puneet’s
author visits to schools, end on a high note with a lively Bollywood dance tutorial,
leaving students inspired and entertained.`,
      `In addition to her writing, Puneet runs her own occasion-wear boutique, with her
designs featured in Vogue, Hello!, and other fashion magazines. She also designed a
Mary Poppins-inspired gown for Miss England to wear at the Miss World 2023 finals.`,
    ],
  },
  //   {
  //     slug: "elhossain-elaimani",
  //     name: "Elhossain Elaimani",
  //     image: "/images/authers/elhoussain-elaimani.jpg",
  //     images: [
  //       "/images/authers/elhoussain-elaimani-jti-2nd-edition.jpg",
  //       "/images/authers/elhoussain-elaimani-L-W-city-hunter-cover.jpg",
  //       "/images/authers/elhoussain-elaimani-tsjm-cover.jpg",
  //     ],
  //     bio: [
  //       `Elhossain Elaimani, alias Elhoussain, is a young Moroccan novelist
  // born in 2000 in Rabat. His family moved to Casablanca when he was
  // five years old, where he stayed until he graduated High School. He
  // holds a Major in political science from the Agdal Faculty of Law in
  // Rabat, where he now lives.`,
  //       `He participated in the first edition of the English Book Festival in
  // 2022, where he presented his novel Journey to Inspiration during one
  // of the festival’s open mic sessions. The novel was also presented
  // during a meeting held in honour of the writer at the Faculty of Arts
  // and Humanities in Ben M’sik in October 2023.`,
  //       `Elhoussain released his second novel, Love & War, in 2024, during
  // the International Book Fair SIEL in Rabat, where he also participated
  // in a round table discussion entitled English Literature, English in
  // young Moroccan creativity.`,
  //       `His latest work published under his alias Elhoussain, is a collection of
  // poetry book “The Tragedy of Sir Jacques De Molay”.`,
  //     ],
  //   },
  // {
  //   slug: "mustapha-fahmi",
  //   name: "Mustapha Fahmi",
  //   image: "/images/authers/mustapha-fahmi.jpg",
  //   images: [
  //     // "/images/mustapha-fahmi.jpeg",
  //     "/images/authers/mustapha-fahmi-la-lecon-de-rosalinde.jpg",
  //     "/images/authers/mustapha-fahmi-la-promesse-de-juliette.jpg",
  //   ],
  //   bio: [
  //     `Mustapha Fahmi was born in Casablanca and holds a Doctorate in English literature at the University of Montreal.  He is a specialist in the English Renaissance and particularly in the work of Shakespeare.`,
  //     `He is the author of several books including Shakespeare's Poetic Wisdom, The Purpose of Playing, Dwelling in the Forest of Arden as well as three collections of poetry in Arabic.`,
  //     `Mustapha Fahmi, while fluent in English, writes principally in French and Arabic.  His book La Leçon de Rosalinde (a collection of philosophical reflections) received the prize for the best book in its category at the 54th edition of the Salon du livre du Saguenay-Lac-Saint-Jean in 2018.  His latest book, La Promesse de Juliette, was published in 2021.`,
  //     `His article "Man's Chief Good", a philosophical reading of the Shakespearean character, appeared in the prestigious Shakespearean International Yearbook (2008), an annual anthology that brings together the most important writings in the world in the field of Shakespearean studies.`,
  //     `Professor Fahmi has given lectures on Shakespeare all over the world, including at the famous Shakespeare Institute at the University of Birmingham, recognized as the temple of Shakespearean studies.`,
  //     `Mr. Fahmi has been twice nominated for the Prix d'Excellence du Réseau des Universités du Québec (2008 and 2012), an award that especially recognizes originality in the field of teaching.`,
  //     `Mustapha Fahmi is the recipient of the 2019 Literary Distinction Prize awarded by the Salon du livre du Saguenay-Lac-Saint-Jean.`,
  //   ],
  // },
  {
    slug: "jane-green",
    name: "Jane Green",
    image: "/images/authers/jane-green-profile.jpg",
    images: [
      // "/images/jane-green-profile.jpeg",
      "/images/authers/jane-green-profile-sister-stardust.jpg",
    ],
    bio: [
      "Jane Green is the author of twenty-one novels including eighteen New York Times bestsellers, and has over ten million books in print.",
      "She is also now  “Dear Jane”, the hugely popular advice columnist for the UK national daily newspaper the Daily Mail, and one of the busiest news websites in the world.",
      "Three of her books have been made into movies for the Lifetime network: Tempting Fate, To Have and to Hold, and Family Pictures.",
      "Together with Helen Fielding of Bridget Jones’s Diary, she is known as one of the founders of a movement called “chick lit”, the literary equivalent of the “rom com”.",
      "Over the past twenty-seven years she has moved on to write wise, warm, emotionally-resonant books about women navigating today’s complicated lives. A graduate of the International Culinary Centre, Jane has written one cookbook: Good Taste.",
      "She is the Founder of Emerald Audio, a female-led podcast network producing original audio dramas for women, including Rainbow Girl, The Key of Love, and Bad Influencer, all available to stream for free wherever you listen to podcasts.",
      "She has taught writing at the popular Kauai Writer’s Conference, and has now started her own Women and Writing Conference in Morocco – Kitaba Marrakech.",
      "A native Londoner, Jane spent twenty-three years raising her four children in Westport, Connecticut, before recently falling head over heels with Marrakech.",
      "Now based in Marrakech, she lives alone and spends every day desperately trying not to pick up every kitten that waits on her doorstep. They know a crazy cat lady when they see one.",
    ],
  },
  {
    slug: "hassan-hilmy",
    name: "Hassan Hilmy",
    image: "/images/authers/hassan-hilmy.jpg",
    images: ["/images/authers/hassan-hilmy-facts-and-factions-cover.jpg"],
    bio: [
      "Hassan Hilmy is professor of English at Hassan II University, Casablanca.",
      "He translated into Arabic the works of such poets as Yeats, Blake, Borges, Rilke, and Hölderlin. He also translated an anthology of contemporary American poetry (Cairo, 1996). In 2016, his translation of Ezra Pound’s poetry received the Sheikh Hamad Award for Translation and International understanding. He occasionally delves into creative writing in both languages.",
      "Hiimy’s translations of Arab poets into English are published in a number of periodicals like Banipal, Aufgabe, The New Review of Literature, and in a number of anthologies like Maghreb: New Writing from North Africa (Talus &amp; University of York, 1992), A Crack in the Wall: New Arab Poetry (London, 2001), and Language for A New Century: Contemporary Poetry from the Middle East, Asia and Beyond (New York, 2008).",
      "In 2022 Hasan Hilmy published Facts and Factions: Digressive Jottings, a collection of over 200 pieces of diverse writing that spans three decades of his work, and in 2024 his translation of British Romantic poetry.",
    ],
  },

  {
    slug: "jane-johnson",
    name: "Jane Johnson",
    image: "/images/authers/jane-johnson.jpg",
    images: [
      // "/images/jane-johnson.jpg",
      "/images/authers/jane-johnson-the-sultan-is-wife.jpg",
      // "/images/jane-johnson-the-black-crescent-jane-johnson-1.jpeg",
      // "/images/jane-johnson-the-black-crescent-jane-johnson-2.jpeg",
      "/images/authers/jane-johnson-the-black-crescent-jane-johnson-3.jpg",
    ],
    bio: [
      "Jane Johnson is a British writer and publisher.  Many of her novels are set in Morocco, after she met and married Abdellatif Bakrim and moved to Tafraout in 2005.",
      "The couple split their time between Cornwall and Morocco, and Jane still works, remotely, as Fiction Publishing Director for HarperCollins, where she has been responsible for the publishing of JRR Tolkien, George RR Martin, Robin Hobb and Dean Koontz.",
      "Her most recent novel, The Black Crescent, set in 1950s Casablanca during the fight for independence, was picked by The Times as one of the best historical novels of 2023.",
    ],
  },
  {
    slug: "amira-bennison",
    name: "Amira K Bennison",
    image: "/images/authers/amira-bennison.jpg",
    images: [
      // "/images/amira-bennison.jpg",
      // "/images/amira-bennison-magdalene.png",
      "/images/authers/amira-bennison-the-almoravid-and-almohad-empires.jpg",
      "/images/authers/amira-bennison-the-great-caliphs.jpg",
    ],
    bio: [
      "Amira K. Bennison is Professor in the History and Culture of the Maghrib and Director of the Centre of Islamic Studies at the University of Cambridge. She is also a Fellow of Magdalene College. Amira’s teaching and research interests include the medieval Islamic West (Islamic Iberia and Morocco), Maghribi modes of legitimation and cultures of power, and 18th-19th century Muslim religio-political discourse and engagement with modernity.",
      "Professor Bennison’s publications include The Almoravid and Almohad Empires (Edinburgh, 2016), The Articulation of Power in Medieval Iberia and the Maghrib (Oxford, 2014); The Great Caliphs: the Golden Age of the ‘Abbasid Empire, (London, 2009) and Jihad and its Interpretations in Pre-Colonial Morocco, (London, 2002), as well as numerous articles.",
      "She is currently working on a monograph on cities and power in the medieval Maghrib.",
      "Amira enjoys leading cultural tours to Morocco and southern Spain and contributing to radio and television programmes on Islamic history. She has been a frequent guest on BBC Radio 4’s ‘In Our Time’ and contributed to the successful BBC history podcast ‘You are Dead to Me’.",
    ],
  },
  {
    slug: "laila-lalami",
    name: "Laila Lalami",
    image: "/images/authers/laila-lalami.jpg",
    images: [
      // "/images/laila-lalami.jpeg",
      "/images/authers/laila-lalami-conditional-citzens.jpg",
      "/images/authers/laila-lalami-secret-son.jpg",
      "/images/authers/laila-lalami-the-moor-is-account.jpg",
      "/images/authers/laila-lalami-the-other-americans.jpg",
    ],
    bio: [
      "Laila Lalami was born in Morocco into a house full of books. After graduating from University Mohamed V in Morocco, she gained her MA at university College London and her PhD at the University of Southern California, both in Linguistics.  She lives in Los Angeles.",
      "Laila Lalami is the author of five books: Her first book, the collection of short stories Hope and Other Dangerous Pursuits (2005), is about a group of Moroccan immigrants who cross the Mediterranean on a lifeboat. It was published to critical acclaim, was named a finalist for the Oregon Book Award and the John Gardner Fiction Prize, and was adopted as a common read by many colleges and universities.  Her second book, Secret Son, was published in 2009.",
      "The Moor’s Account (2014) won the American Book Award, the Arab-American Book Award, and the Hurston / Wright Legacy Award. It was on the longlist for the Booker Prize and was a finalist for the Pulitzer Prize in Fiction.",
      "Her fourth novel, The Other Americans (2019), was a national bestseller, won the Joyce Carol Oates Prize, and was a finalist for the National Book Award in Fiction. It was followed by Conditional Citizens in 2020.",
      "Her books have been translated into twenty languages. Her essays have appeared in the Los Angeles Times, the Washington Post, The Nation, Harper’s, the Guardian, and the New York Times.",
      "She has been awarded fellowships from the British Council, the Fulbright Program, the Guggenheim Foundation, and the Radcliffe Institute at Harvard University.  She lives in Los Angeles.",
    ],
  },

  {
    slug: "fouad-laroui",
    name: "Fouad Laroui",
    image: "/images/authers/fouad-laroui.jpg",
    images: [
      // "/images/fouad-laroui.jpeg",
      "/images/authers/fouad-laroui-the-curious-case-of-doussakine-is-trousers.jpg",
    ],
    bio: [
      "Fouad Laroui was born in 1958 in Oujda, Morocco. After his studies in the Lycée Lyautey (Casablanca), he joined the prestigious École Nationale des Ponts et Chaussées (Paris, France), where he studied engineering.",
      "After having worked in the Office Cherifien des Phosphates company in Khouribga (Morocco), he moved to the United Kingdom where he spent several years in Cambridge and York. Later he obtained a PhD in economics and moved to Amsterdam where he is currently teaching econometrics and environmental science.",
      "In addition, he is devoted to writing. He is a literary chronicler for the weekly magazine Jeune Afrique and Economia Magazine, and the French-Moroccan radio Médi1.",
      "He has published over twenty novels and collections of short stories, poetry, and essays who lives between Amsterdam, Paris, and Casablanca. His novels have been shortlisted numerous times for the Prix Goncourt, France's most prestigious literary prize, and his latest novel was awarded the Grand Prix Jean Giorno. The Curious Case of Doussakine's Trousers won Laroui his first Prix Goncourt for short stories.",
    ],
  },
  {
    slug: "hassan-mekouar",
    name: "Hassan Mekouar",
    image: "/images/authers/hassan-mekouar.jpg",
    images: [
      // "/images/hassan-mekouar.jpeg",
      "/images/authers/hassan-mekouar-soft-sue-cover.jpg",
    ],
    bio: [
      "Hassan Mekouar is Professor of American Literature at the Faculty of Letters, University Mohammed V, Rabat. He was Dean of the same faculty and President of the University Mohamed I of Oujda in North East Morocco.",
      "With five published volumes, Hassan Mekouar is the most prolific Moroccan poet writing in English.",
    ],
  },
  {
    name: "Mbarek Sryfi",
    slug: "mbarek-sryfi",
    image: "/images/authers/mbarek-sryfi.jpeg",
    images: [
      "/images/authers/mbarek-sryfi-book1.jpeg",
      "/images/authers/mbarek-sryfi-book2.jpeg",
    ],
    bio: [
      "Mbarek Sryfi was born in Morocco. He holds a BA in English Literature from the University of Sidi Mohamed Ben Abdellah in Fez, Morocco; an MA in Education, Rabat, Morocco; and a PhD in Modern Arabic Literature and Islamic Studies from the University of Pennsylvania. He lives with his family in Pennsylvania.",
      "Dr. Sryfi is a scholar, translator, and poet. He is the author of five collections of poetry, both in English and Arabic: The Trace of a Smile (2018), which won The Moonstone Arts Center's Annual Chapbook Contest; City Poems (2020); Chasing a Moving Landscape (2022); lā taqṣuṣ ru‘yāka ‘alā iḳhwatik (2022); aṣamt, makān wahḭd (2024); and co-edited Moroccan Verse from America (2024), an anthology of Moroccan poets residing in the USA.",
      "He has co-translated and translated many works of poetry, fiction, and literary criticism from Arabic and French, among them Arabs and the Art of Storytelling: A Strange Familiarity, Syracuse University Press (2014), a finalist for the 2015 French-American Foundation Translation Prize in Non-Fiction; Monarch of the Square: An Anthology of Muhammad Zafzaf’s Short Stories (2014); The Elusive Fox by Muhammad Zafzaf (2016); The Blueness of the Evening by Hassan Najmi (2018), longlisted for the 2018 Saif Ghobash Banipal Prize for Arabic Literary Translation; With Urgency: A Selection of Poems by Aicha Bassry (2021), The Handsome Jew by Ali-al-Muqri (2022); and Alone by the Nile: A Selection of Poems by Ahmad al-Shahawy (2023).",
      "His poetry has been published in many literary magazines and anthologies, and has been translated into Arabic, Italian, and Spanish. He has also published peer-reviewed articles and book reviews in many literary magazines.",
      "He has been awarded an NEA grant (2022) and an endowment from The Virginia Center for Creative Arts (2015).",
    ],
  },

  {
    slug: "fiona-valpy",
    name: "Fiona Valpy",
    image: "/images/authers/fiona-valpy.jpg",
    images: [
      // "/images/fiona-valpy.jpeg",
      "/images/authers/fiona-valpy-the-storyteller-of-casablanca.jpg",
    ],
    bio: [
      "Fiona Valpy is an acclaimed bestselling author, with world-wide sales totalling over three million copies. Her novels have held the number 1 position on the Amazon Kindle charts in the USA, UK and Australia, and been listed as Washington Post and Wall Street Journal bestsellers. Translation rights have been sold in 30 countries for more than 100 foreign language editions.",
      "Fiona is a patron of the Women’s Prize for Fiction, Birnam Book Festival, Innerpeffray Library, The Teapot Trust and The Little Sherpa Foundation. Through donations from royalties, she’s raised £12,000 (and counting) for the global charity Médecins Sans Frontières and The Little Sherpa Foundation. When not writing, she enjoys daily dips in the River Tay and walking in the hills around her home in Scotland.",
    ],
  },

  // {
  //   slug: "elhoussain-alaimane",
  //   name: "Elhoussain Alaimane",
  //   image: "/images/elhoussain-alaimane.jpeg",
  //   images: [
  //     // "/images/elhoussain-alaimane.jpeg",
  //     "/images/elhoussain-alaimane-a-journey-to-inspiration.jpeg",
  //   ],
  //   bio: [],
  // },
];
