import React from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
const events = [
  { year: "2022", month: "January", description: "Started a new project" },
  { year: "2022", month: "May", description: "Launched version 1.0" },
  { year: "2023", month: "February", description: "Reached 10,000 users" },
  // Add more events as needed
];

function ProgrammeScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-fixed bg-cover bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          // src="/images/MEBF_Website_BG_2025.jpg"
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white shadow">
            <div className="font-bold text-2xl mt-10 mb-5 text-center">
              The Marrakech English Book Festival
            </div>
            <div className="">
              <section>
                <div className="py-2 ">
                  <div className=" mx-auto flex flex-col items-start md:flex-row my-2 ">
                    <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2  px-2">
                      <p className="ml-2 font-bold uppercase tracking-loose">
                        Friday 17th January 2025
                      </p>
                      <p className="text-xs leading-normal md:leading-relaxed mb-1"></p>
                      {/* <p className="text-[10px]  mb-1">
                        Moderators:
                        <br />
                        Nick Cherkas (NC)
                        <br />
                        Imane AlQaraoui (IA)
                        <br />
                        Abdellah Taibi (AT)
                        <br />
                        Amna ElFadilala (AE)
                        <br />
                      </p> */}
                      {/* <p className="text-xs leading-normal md:leading-relaxed mb-1">
                        10h00–10h30 Opening ceremony
                      </p>
                      <p className="text-xs  mb-2">
                        Main events will be held at our partner venue, the
                        Moroccan National Museum Foundation’s Dar El Bacha
                        Museum of Confluences.
                      </p> */}
                    </div>
                    <div className="ml-0 lg:w-2/3 w-full sticky bg-red-500 rounded">
                      <div className="container mx-auto w-full h-full">
                        <div className="relative wrap overflow-hidden p-2 h-full">
                          <div
                            className="border-2-2 border-[#fff]  border-yellow-555 absolute h-full "
                            style={{
                              right: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div
                            className="border-2-2 border-yellow-555 absolute h-full border"
                            style={{
                              left: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>

                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline ">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right ">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Outside Courtyard, Dar El Bacha
                              </h1>
                              <p className="text-xs">10h00 – 10h30</p>
                              <p className="text-sm  leading-snug ">
                                Opening Ceremony: Welcome from Yassin Adnan,
                                Marrakech English Book Association President,
                                and Salima Ait M’bark, Conservateur - Fondation
                                Nationale des Musées
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">10h30 – 11h30</p>
                              <p className="text-sm  leading-snug ">
                                Jane Green : "The Magic of Marrakech in the
                                Sixties"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">11h30 – 12h30</p>
                              <p className="text-sm  leading-snug ">
                                Mhani Alaoui : "Gender, Heritage and Class
                                Relations in the Novel"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4 text-left">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Interior Courtyard, Dar El Bacha
                              </h1>
                              {/*  */}
                              <p className="text-xs">12h00 – 14h30</p>
                              <p className="text-sm  leading-snug ">Lunch</p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">14h30 – 15h30</p>
                              <p className="text-sm  leading-snug ">
                                Moroccan Poets - a culture in verse. Hassan
                                Hilmy, Hassan Mekouar, Mbarek Sryfi, Hafsa
                                Bekri. Moderated by Alexandra Balafrej, British
                                Council Morocco Director
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">15h30 – 16h30</p>
                              <p className="text-sm  leading-snug ">
                                Jane Johnson : "How Barbary pirate raids in
                                Cornwall in 1625 led me to Morocco"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">16h30 – 17h30</p>

                              <p className="text-sm  leading-snug ">
                                Mbarek Sryfi : "Translation as Soft Power" in
                                conversation with Marcia Lynx Qualey
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*  */}
              <section>
                <div className="py-2 ">
                  <div className=" mx-auto flex flex-col items-start md:flex-row my-2 ">
                    <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2  px-2">
                      <p className="ml-2 font-bold uppercase tracking-loose">
                        Saturday 18th January 2025
                      </p>
                      <p className="text-xs leading-normal md:leading-relaxed mb-1"></p>
                      {/* <p className="text-[10px]  mb-1">
                        Moderators:
                        <br />
                        Nick Cherkas (NC)
                        <br />
                        Imane AlQaraoui (IA)
                        <br />
                        Abdellah Taibi (AT)
                        <br />
                        Amna ElFadilala (AE)
                        <br />
                      </p> */}
                    </div>
                    <div className="ml-0 lg:w-2/3 w-full sticky bg-red-500 rounded">
                      <div className="container mx-auto w-full h-full">
                        <div className="relative wrap overflow-hidden p-2 h-full">
                          <div
                            className="border-2-2 border-[#fff]  border-yellow-555 absolute h-full "
                            style={{
                              right: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div
                            className="border-2-2 border-yellow-555 absolute h-full border"
                            style={{
                              left: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          {/*  */}
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline ">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right ">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Outside Courtyard, Dar El Bacha
                              </h1>
                              <p className="text-xs">10h00 – 11h00</p>
                              <p className="text-sm  leading-snug ">
                                Laila Lalami in conversation with Mbarek Sryfi
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">11h30 – 12h30</p>
                              <p className="text-sm  leading-snug ">
                                Amira K Bennison : "Marrakesh and medieval ideas
                                of Maghribi Islamic Empire"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>

                              {/*  */}
                              <p className="text-xs">15h00 – 16h00</p>
                              <p className="text-sm  leading-snug ">
                                Costa Godex Book Award shortlisted authors:
                                Mhani Alaoui, Ghita Ayase Ouamane
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4 text-left">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Interior Courtyard, Dar El Bacha
                              </h1>

                              {/*  */}
                              <p className="text-xs">14h30 – 15h30</p>
                              <p className="text-sm  leading-snug ">
                                Hassan Hilmy : "Digressive Jottings: Three
                                Decades of Writing"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">15h30 – 16h30</p>
                              <p className="text-sm  leading-snug ">
                                Khalid Bekkaoui : "Necklace and Pomegranetes"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">16h30 – 17h30</p>
                              <p className="text-sm  leading-snug ">
                                Fouad Laroui : "Can literature bridge the gap
                                between cultures?"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*  */}
              <section>
                <div className="py-2 ">
                  <div className=" mx-auto flex flex-col items-start md:flex-row my-2 ">
                    <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2  px-2">
                      <p className="ml-2 font-bold uppercase tracking-loose">
                        Sunday 19th January 2025
                      </p>
                      <p className="text-xs leading-normal md:leading-relaxed mb-1"></p>
                      {/* <p className="text-[10px]  mb-1">
                        Moderators:
                        <br />
                        Nick Cherkas (NC)
                        <br />
                        Imane AlQaraoui (IA)
                        <br />
                        Abdellah Taibi (AT)
                        <br />
                        Amna ElFadilala (AE)
                        <br />
                      </p> */}
                    </div>
                    <div className="ml-0 lg:w-2/3 w-full sticky bg-red-500 rounded">
                      <div className="container mx-auto w-full h-full">
                        <div className="relative wrap overflow-hidden p-2 h-full">
                          <div
                            className="border-2-2 border-[#fff]  border-yellow-555 absolute h-full "
                            style={{
                              right: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div
                            className="border-2-2 border-yellow-555 absolute h-full border"
                            style={{
                              left: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          {/*  */}
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline ">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right ">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Outside Courtyard, Dar El Bacha
                              </h1>
                              <p className="text-xs">10h00 – 11h00</p>
                              <p className="text-sm  leading-snug ">
                                Abigail Assor : “As Rich as the King”: A
                                bittersweet love letter to Casablanca
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">11h30 – 12h30</p>
                              <p className="text-sm  leading-snug ">
                                Hassan Mekouar : Soft Sue and Other (Goodbye)
                                Poems: Prelude to the Preludes or Prefacing the
                                Preface
                              </p>

                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4 text-left">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Interior Courtyard, Dar El Bacha
                              </h1>

                              {/*  */}
                              <p className="text-xs">14h30 – 15h30</p>
                              <p className="text-sm  leading-snug ">
                                Fiona Valpy : "Dual timeline fiction: contrast,
                                congruences and connections"
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">15h30 – 17h00</p>
                              <p className="text-sm  leading-snug ">
                                "Open Mic" Sessions - writers introduce their
                                work to the audience for 10 minutes each
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">17h00 – 17h30</p>
                              <p className="text-sm  leading-snug ">
                                Closing Ceremony
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>

                          {/*  */}
                          <div className="m flex justify-between flex-row-reverse items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4 text-left">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Dar Bellarj Childrens’ Room
                              </h1>
                              {/*  */}
                              <p className="text-xs">10h00 – 11h00</p>
                              <p className="text-sm  leading-snug ">
                                ESAV Drawing Workshop for children
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">12h00 – 13h00</p>
                              <p className="text-sm  leading-snug ">
                                Hannah Ait Ahmed - Tom's Tall Story
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">14h00 – 15h00</p>
                              <p className="text-sm  leading-snug ">
                                Puneet Bhandal - Bollywood workshop
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">16h00 – 17h00</p>
                              <p className="text-sm  leading-snug ">
                                Mehrdokht Amini
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="m flex justify-between items-center w-full left-timeline ">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right ">
                              <h1 className="text-sm text-white font-bold border-b mb-3">
                                Dar Bellarj Professional Room
                              </h1>
                              <p className="text-xs">11h00 – 12h00</p>
                              <p className="text-sm  leading-snug ">
                                IBBY : An introduction. An overview of IBBY
                                Morocco by its representatives, Amina Hachami
                                Alaoui, Yasmine El Kaouakibi and Zineb Beladel.
                                Moderated by Alexandra Belafrej.
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">15h00 – 16h00</p>
                              <p className="text-sm  leading-snug ">
                                Childrens' Book Illustration and design forum :
                                the importance of book design, typesetting and
                                formatting.
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                              {/*  */}
                              <p className="text-xs">17h00 – 18h00</p>
                              <p className="text-sm  leading-snug ">
                                How to create a platform to put young authors in
                                touch with young graphic designers.
                              </p>
                              {/*  */}
                              <div className="my-2"></div>
                            </div>
                          </div>

                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
      {/*  */}

      {/*  */}
    </div>
  );
}

export default ProgrammeScreen;
