import React from "react";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";

function AboutScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")", }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          // src="/images/MEBF_Website_BG_2025.jpg"
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white shadow">
            <div className="font-bold text-2xl mt-10 mb-5 text-center">
              The Marrakech English Book Festival
            </div>
            <p className="my-5 md:text-base text-sm">
              The 2025 edition of the Marrakech English Book Festival is a
              collaborative partnership between the Marrakech English Book
              Association and the British Council in Morocco.
            </p>
            <p className="my-5 md:text-base text-sm">
              The festival is co-hosted by our venue partners the Moroccan
              National Museums Foundation (FNM), who have allowed the festival
              events to take place at the historic Dar El Bacha Museum of
              Confluences, and our accommodation partners the JAAL Riad Resort
              Marrakech, who are graciously hosting all the festival’s guest
              authors.
            </p>
            <p className="my-5 md:text-base text-sm">
              The festival also enjoys the support of our corporate sponsors
              Travel Link Morocco, Aynnaka and the Rotisserie de la Paix.
            </p>
            <p className="my-5 md:text-base text-sm">
              The program of children’s events is made possible by the support
              of our partner the British Academy School Marrakech and the
              Foundation Dar Bellarj.
            </p>
            <p className="my-5 md:text-base text-sm">
              The MEBF is a celebration of Marrakech and Morocco as an
              inspiration for all genres of books published in the English
              language. This second edition of the MEBF will also inaugurate a
              new National Book Prize, The Costa Goldex Book Award, which will
              be awarded at a ceremony during the festival alongside the
              National Short Story Prize, organised by the Oliveseed Foundation
              and the Morocco Libraries Project.
            </p>
            <p className="my-5 md:text-base text-sm">
              The content of the festival is guided by a small independent
              Literary Advisory Committee.
            </p>
            <p className="my-5 md:text-base text-sm">
              The festival comprises of a series of events, ranging from
              discussions and interviews with invited authors, children’s book
              events (including exhibitions, readings, and illustration
              workshops). The language of the festival is English.
            </p>
            <p className="my-5 md:text-base text-sm">
              The core festival events will be held over three days on the
              weekend of 17th to 19th January 2025. The public events will be
              held at the Museum Des Confluences Dar El Bacha, with a number of
              professional discussions and children's events held at Dar
              Bellarj. Both venues are of great architectural, historical and
              cultural significance in Marrakech.
            </p>
            <p className="my-5 md:text-base text-sm">
              An educational program, held prior to the weekend of the festival,
              is organized in partnership with the English department of the
              Cadi Ayyad University and the University Mohammed VI Polytechnic
              (UM6P) with the aim of giving Moroccan university students a
              chance to learn from, meet and be inspired by published authors in
              the English language.
            </p>
            <p className="my-5 md:text-base text-sm">
              This year we will also launch a visual arts program in partnership
              with the Ecole Supérieure des Arts Visuels (ESAV) in Marrakech to
              promote and celebrate the art of book design and illustration in
              Morocco.
            </p>
            <p className="my-5 md:text-base text-sm">
              The invited authors have all published books in English that have
              been inspired by Marrakech, Morocco or North Africa.
            </p>
            <p className="my-5 md:text-base text-sm">
              A pop-up book market near one of the festival venues will sell
              books in English.
            </p>
          </div>
        </div>
        {/* footer */}
        <PartnerWidget />
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default AboutScreen;
