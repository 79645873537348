import React, { useState } from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function ContactScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          // src="/images/MEBF_Website_BG_2025.jpg"
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container md:mx-auto  px-5 py-10 mt-5 md:w-1/2 w-full  mb-5 min-h-[70vh] bg-white shadow">
            <p className=" text-center text-xl my-10 mx-auto w-full font-bold">
              Contact Us
            </p>
            {/* full name */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  First Name <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      firstNameError ? "border-red-500" : "border-[#00000052]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Full Name"
                    value={firstName}
                    onChange={(v) => setFirstName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger-500">
                    {firstNameError ? firstNameError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pl-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Last Name
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      lastNameError ? "border-red-500" : "border-[#00000052]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(v) => setLastName(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger-500">
                    {lastNameError ? lastNameError : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Email <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      emailError ? "border-red-500" : "border-[#00000052]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger-500">
                    {emailError ? emailError : ""}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="md:w-1/2 w-full  md:pl-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Phone
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      phoneError ? "border-red-500" : "border-[#00000052]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="phone"
                    placeholder="Phone"
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger-500">
                    {phoneError ? phoneError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-1/2 w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Subject <strong className="text-danger">*</strong>
                </div>
                <div>
                  <input
                    className={` outline-none border ${
                      subjectError ? "border-red-500" : "border-[#00000052]"
                    } px-3 py-2 w-full rounded text-sm`}
                    type="text"
                    placeholder="Subject"
                    value={subject}
                    onChange={(v) => setSubject(v.target.value)}
                  />
                  <div className=" text-[8px] text-danger-500">
                    {subjectError ? subjectError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex md:flex-row flex-col  ">
              <div className="md:w-full w-full  md:pr-1 my-1">
                <div className="text-[#000000bf] font-bold text-xs  mb-1">
                  Message <strong className="text-danger">*</strong>
                </div>
                <div>
                  <textarea
                    value={message}
                    onChange={(v) => setMessage(v.target.value)}
                    className={` outline-none border ${
                      messageError ? "border-red-500" : "border-[#00000052]"
                    } px-3 py-2 w-full rounded text-sm`}
                    rows={4}
                  ></textarea>

                  <div className=" text-[8px] text-danger-500">
                    {messageError ? messageError : ""}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-row items-center justify-end my-4 ">
              <a href="/" className="text-[##00000069] font-bold mx-3 text-sm">
                Home page
              </a>
              <button
                disabled={isLoading}
                onClick={async () => {
                  var check = true;
                  setFirstNameError("");
                  setLastNameError("");
                  setEmailError("");
                  setPhoneError("");
                  setSubjectError("");
                  setMessageError("");

                  if (firstName === "") {
                    setFirstNameError("This field is required.");
                    check = false;
                  }

                  if (email === "") {
                    setEmailError("This field is required.");
                    check = false;
                  } else if (!validateEmail(email)) {
                    setEmailError("Please enter a valid email address.");
                    check = false;
                  }

                  if (subject === "") {
                    setSubjectError("This field is required.");
                    check = false;
                  }

                  if (message === "") {
                    setMessageError("This field is required.");
                    check = false;
                  }

                  if (check) {
                    setIsLoading(true);
                    try {
                      const response = await axios.post(
                        "https://api.mebookfest.com/api/messages/send/",
                        {
                          first_name: firstName ?? "",
                          last_name: lastName ?? "",
                          email: email ?? "",
                          phone: phone ?? "",
                          subject: subject ?? "",
                          message: message ?? "",
                        }
                      );

                      // setResponseMessage(response.data.detail);
                      toast.success("Your message has been sent successfully.");
                      setFirstName("");
                      setFirstNameError("");
                      setLastName("");
                      setLastNameError("");
                      setEmail("");
                      setEmailError("");
                      setPhone("");
                      setPhoneError("");
                      setSubject("");
                      setSubjectError("");
                      setMessage("");
                      setMessageError("");
                      setIsLoading(false);
                    } catch (error) {
                      setIsLoading(false);
                      toast.error(
                        "Your message was not sent. Please try again. "
                      );
                    }
                  } else {
                    toast.error(
                      "Some fields are empty or invalid. please try again"
                    );
                  }
                }}
                className="bg-black text-white px-5 py-2 rounded-md font-bold text-sm mx-3 flex flex-row items-center"
              >
                {isLoading ? (
                  <div role="status mx-1">
                    <svg
                      aria-hidden="true"
                      className="size-4 text-gray-200 animate-spin  fill-black"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : null}
                <div className="mx-1">Confirm</div>
              </button>
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default ContactScreen;
