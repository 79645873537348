import React from "react";
import HeaderWidget from "../widgets/HeaderWidget";
import backgroundImage from "../assets/images/background-image.jpg";
import logoMEBFHome from "../assets/icons/logo-mebf-home.png";
import FooterWidget from "../widgets/FooterWidget";
// import logoTENBOOK from "../assets/icons/LOGO_THE_ENGLISH_BOOK.png";
import PartnerWidget from "../widgets/PartnerWidget";

import logoBritishCouncil from "../assets/icons/BritishCouncil_Logo_Indigo_RGB.png";

function HomeScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen w-full  bg-opacity-70 flex flex-col">
        <img
          src={backgroundImage}
          // src="/images/MEBF_Website_BG_2025.jpg"
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />

        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto px-5 min-h-[70vh] flex flex-col justify-center shadow">
            <img
              src={logoMEBFHome}
              alt="MEBF 2025"
              className="mx-auto mt-16 md:w-1/2 w-full"
            />
            <p className="text-center font-medium md:text-2xl text-xl  w-full px-3 mx-auto mt-3 mb-4">
              MARRAKECH ENGLISH BOOK FESTIVAL
            </p>
            <p className="text-center font-bold md:text-4xl text-2xl  w-full px-3  mx-auto mt-3 mb-4">
              17 - 19 JANUARY 2025
            </p>
            <img
              src={"/images/main_full.png"}
              className="mx-auto h-28 mt-3 mb-5"
            />
            {/* <div className="bg-[#eeefef]  mx-auto px-10 py-4 text-center rounded">
              <div className="text-[#23095b] mb-3 text-xl">
                In collaboration with
              </div>
              <img src={logoBritishCouncil} className="w-48" />
            </div> */}
            {/* <a href="/detail-info" className=" w-full">
              <p className=" text-white cursor-pointer bg-[#a15656] px-5 py-5 text-center font-medium md:text-xl text-xl md:w-1/2 mx-auto  w-full    mt-6 mb-4">
                <p className="my-2 text-2xl">THE COSTA GOLDEX BOOK AWARD</p>
                <p className="my-2 text-base">
                  The Deadline for Entries is 31st JULY 2024
                </p>
                <p className="my-2 text-base ">
                  CLICK HERE for details & an entry form
                </p>
              </p>
            </a> */}
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default HomeScreen;
