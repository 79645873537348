import React from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";

function CreditScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          // src="/images/MEBF_Website_BG_2025.jpg"
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white shadow">
            <div className="font-bold text-2xl mt-10 mb-5 text-center">
              The Marrakech Book Festival Credits
            </div>
            <div className="my-2">
              The Festival is organized by the Marrakech English Book
              Association, which is made up of a group of volunteers under the
              Presidency of Yassin Adnan. They are Manel Azouz, Nick Cherkas,
              Juanita Folmsbee, Khaoula Jabal, Alan Keohane, Sarah Nait and
              Soleiman Nait.
            </div>
            <div className="my-2">
              We also would like to thank our team of unpaid festival
              volunteers, from the ESAV and EFA, without whose help on the
              ground the festival would have descended into chaos.
            </div>
            <div className="my-2">
              We are eternally grateful to the support of our principal partner
              and collaborator the British Council, led by Country Director
              Alexandra Balafrej, without whose support the 2025 edition of the
              festival would not have been possible.
            </div>
            <div className="my-2">
              Thanks to the support of the Moroccan National Museum Foundation
              (FNM), led by Foundation President Mehdi Qobti, we were able to
              hold this years festival events at the stunning Dar El Bacha
              Museum of Confluences and our thanks to Mdame Salima Ait M’Bark,
              Conservator of the Dar el Bacha Museum, for being such a willing
              and enthusiastic partner in the festival.
            </div>
            <div className="my-2">
              The festival is also only possible thanks to the generosity of our
              accommodation partner, JAAL Riad Resort, with special thanks to GM
              Amal Moufid and Valérie Tazi.
            </div>
            <div className="my-2">
              We owe sincere thanks also our transport partner are Travel Link,
              led by CEO Othman Nait and GM Ali Kasmi, and our hospitality
              partner Imane Rmili, President of the Moroccan National Restaurant
              Association, Vice President of CRT Marrakech Safi and owner of the
              Rotisserie de la Paix and to Aynnaka, our website and e-ticketing
              partner. The CCME have also very graciously sponsored the
              participation of Laila Lalami and Mbarek Sryfi, our two Moroccan
              authors from the USA.
            </div>
            <div className="my-2">
              As in our inaugural edition of the festival we are indebted to our
              academic partners and most especially to the generosity of Nabeel
              Obiad and Natasha Compton, from the British Academy School
              Marrakech and our university partners at the Language Lab of UM6P,
              led by Dr Ahmed Tayel and Ismail Raqi, and Professor Fatima Zohra
              Ilfahen, head of the Cadi Ayyad English Department and Professor
              Ahmed Kadim, head of the Faculty of Arabic Language at UCA
              Marrakech.
            </div>
            <div className="my-2">
              We are also very grateful to our new academic collaborators at the
              Graphics Department at the Ecole Superior des Art Visuels (ESAV)
              in Marrakech, particularly Cédric Gattillon, Lola Duval and
              Sibylle Baltzer.
            </div>
            <div className="my-2">
              The Dar Bellarj Cultural Centre in Marrakech, under the direction
              of Maha El Madi, are our very special collaborators in the Medina.
            </div>
            <div className="my-2">
              We are honoured to have launched our collaboration with Diljit
              Brar and Marco Cremonini, from Costa Goldex, and Barb Mackaz and
              Moulay Larbi Arbaoui, from the Oliveseed Foundation, for the new
              Costa Goldex National Book Award and the MLP Short Story Prize.
            </div>

            <div className="my-2">
              Also special thanks to Yacine Retnani and Miriam from Carrefour
              des Livres in Casablanca, our bookshop partners for the festival.
            </div>
            <div className="my-2">
              Finally we would like to thank all the authors and members of the
              public, whose enthusiasm and support make the festival such a
              unique and enjoyable event.
            </div>
          </div>
        </div>
        {/*  */}

        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default CreditScreen;
