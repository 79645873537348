import React from "react";
import logoAynnaka from "../assets/icons/aynnaka-logo-rect.jpg";
import logoBasm from "../assets/icons/basm-logo-rect.jpg";
import logoGoldexWording from "../assets/icons/goldex-wording-logo-rect.jpg";
import logoJaal from "../assets/icons/nouveau-logo-jaal.jpeg";
// import logoJaal from "../assets/icons/jaal-logo-rect.jpg";
import logoTravlink from "../assets/icons/travlink-logo-rect.jpg";
import logoOliveseed from "../assets/icons/oliveseed-logo.jpg";
import logoUca from "../assets/icons/uca-logo.jpg";
import logoUm6p from "../assets/icons/um6p-logo.jpg";
import logoFNM from "../assets/icons/FNM logo.png";
// import logoFNM from "../assets/icons/fnm-logo.jpg";
import logoESAV from "../assets/icons/ESAV-Ecole-Superieure-des-Arts-Visuels-de-Marrakech-900x420-1.png";
import logoELFENN from "../assets/icons/EL-FENN-BLACK.png";
import logoDARBELLARJ from "../assets/icons/Logo-Dar Bellarj-01.png";
import logoCCME from "../assets/icons/ccme-logo.jpeg";
import logoESAVtwo from "../assets/icons/esav-logo.jpeg";
import logoCOSTA from "../assets/icons/costa-logo.png";

function PartnerWidget() {
  return (
    <div className="w-screen bg-white p-5 mt-5 z-20 shadow">
      <div className=" flex md:flex-row flex-col item-center">
        <img className="md:w-1/2 w-full" src="/images/bg-footer-one.png" />
        <img className="md:w-1/2 w-full" src="/images/bg-footer-two.png" />
      </div>
      {/* <div className="container mx-auto">
        <div className="flex  flex-col justify-center px-3 ">
          <div className="flex md:flex-row flex-col justify-center px-3 ">
            <div className="flex flex-row justify-center ">
              <img
                className="m-1 w-32 h-20"
                src={logoFNM}
                alt="Fondation National des Musees"
              />
              <img
                className="m-1 w-32 h-20 "
                src={logoOliveseed}
                alt="Oliveseed"
              />
            </div>
            <div className="flex flex-row justify-center ">
              <img className="m-1 w-32 h-20" src={logoJaal} alt="Jaal" />
              <img className="m-1 w-32 h-20" src={logoBasm} alt="Basm" />
            </div>
            <div className="flex flex-row justify-center ">
              <img
                className="m-1 w-32 h-20 "
                src={logoCOSTA}
                alt="COSTA COFEE "
              />
              <img className="m-1 w-32 h-20 " src={logoAynnaka} alt="Aynnaka" />
            </div>
          </div>

          <div className="flex md:flex-row flex-col justify-center px-3 ">
            <div className="flex flex-row justify-center ">
              <img className="m-1 w-32 h-20 " src={logoTravlink} alt="Basm" />
              <img className="m-1 w-32 h-20 " src={logoUca} alt="UCA" />
            </div>
            <div className="flex flex-row justify-center ">
              <img className="m-1 w-32 h-20 " src={logoUm6p} alt="UM6P" />

              <img className="m-1 w-32 h-20  " src={logoELFENN} alt="El-FENN" />
            </div>
            <div className="flex flex-row justify-center ">
              <img
                className="m-1 w-32 h-20 "
                src={logoDARBELLARJ}
                alt="DAR BELLARJ"
              />
              <img className="m-1 w-32 h-20 " src={logoCCME} alt="CCME" />
            </div>
          </div>
          <div className="flex md:flex-row flex-col justify-center px-3 ">
            <div className="flex flex-row justify-center ">
              <img className="m-1 w-32 h-20 " src={logoESAVtwo} alt="ESAV" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default PartnerWidget;
